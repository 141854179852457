import { type IncomeReportType } from "../../components/IncomeReportCard/IncomeReportCard";
import axiosInstance from "../../services/axiosInstance";
import {
	type BanksListOptionsType,
	type BillingEditPayloadType,
	type BillingPayloadType,
	type DateEventType,
	type EditExperiencesType,
	type ExperiencesType,
	type IndicationsType,
	type InstitutesType,
	type InteractionsPayloadType,
	type NewExperienceType,
	type ReceiptMethodsType,
	type filtersType,
	type finishInteractionType,
	type notificationsType,
} from "../../types/workspaceContext";
import { type AccountRegisterDetailsType } from "../../hooks/WorkspaceHook/accountWorkspaceHook";
import { isEmpty } from "lodash";
import { formatDateToQueryParam } from "../queryParamsFormatting/queryParamsFormatting";
import type { AxiosResponse } from "axios";

export const getExpertExperiences = async (): Promise<
	AxiosResponse<ExperiencesType[], any>
> =>
	await axiosInstance.get<ExperiencesType[]>(
		"/workspace/dashboard/experiences",
	);

export const getExpertRecommendations = async (): Promise<
	AxiosResponse<IndicationsType[], any>
> =>
	await axiosInstance.get<IndicationsType[]>("/workspace/recommendation/list");

export const getExpertInteractions = async ({
	filterParams,
	customerParam,
}: {
	filterParams: filtersType;
	customerParam: number[];
}): Promise<AxiosResponse<InteractionsPayloadType, any>> =>
	await axiosInstance.get<InteractionsPayloadType>(
		"/workspace/interaction/get",
		{
			params: {
				initial_date: filterParams.dateFilters.startDate
					? formatDateToQueryParam(filterParams.dateFilters.startDate)
					: undefined,
				end_date: filterParams.dateFilters.endDate
					? formatDateToQueryParam(filterParams.dateFilters.endDate)
					: undefined,
				status: !isEmpty(filterParams.statusFilters)
					? JSON.stringify(filterParams.statusFilters)
					: undefined,
				customer: !isEmpty(customerParam)
					? JSON.stringify(customerParam)
					: undefined,
				actionsOrder:
					filterParams.actionsOrder !== "default"
						? filterParams.actionsOrder
						: undefined,
				customerOrder:
					filterParams.customerOrder !== "default"
						? filterParams.customerOrder
						: undefined,
				durationOrder:
					filterParams.durationOrder !== "default"
						? filterParams.durationOrder
						: undefined,
				rateOrder:
					filterParams.rateOrder !== "default"
						? filterParams.rateOrder
						: undefined,
				statusOrder:
					filterParams.statusOrder !== "default"
						? filterParams.statusOrder
						: undefined,
				start_endOrder:
					filterParams.start_endOrder !== "default"
						? filterParams.start_endOrder
						: undefined,
				dateOrder:
					filterParams.dateOrder !== "default"
						? filterParams.dateOrder
						: undefined,
			},
		},
	);

export const getInteractionCustomers = async (
	searchParam: string | undefined,
) =>
	await axiosInstance.get<Array<{ label: string; value: number }>>(
		"/workspace/interaction/customers",
		{
			params: {
				customer_name: searchParam || undefined,
			},
		},
	);

export const getIncomeReports = async () =>
	await axiosInstance.get<IncomeReportType[]>("/workspace/income/list");

export const getIncomeFile = (income_id: number, name: string): void => {
	axiosInstance
		.get<Blob>(`/workspace/income/get/${income_id.toString()}`, {
			responseType: "blob",
		})
		.then(({ data }) => {
			const blob = new Blob([data], {
				type: "application/pdf;base64",
			});
			const blobURL = window.URL.createObjectURL(blob);
			const tempLink = document.createElement("a");
			tempLink.style.display = "none";
			tempLink.href = blobURL;
			tempLink.download = name;
			tempLink.click();
			window.URL.revokeObjectURL(blobURL);
		});
};

export const getInteractionsExcel = ({
	filterParams,
	customerParam,
}: {
	filterParams: filtersType;
	customerParam: number[];
}): void => {
	axiosInstance
		.get<Blob>("/workspace/interaction/download", {
			responseType: "blob",
			params: {
				initial_date:
					filterParams.dateFilters.startDate && filterParams.dateFilters.endDate
						? formatDateToQueryParam(filterParams.dateFilters.startDate)
						: undefined,
				end_date:
					filterParams.dateFilters.startDate && filterParams.dateFilters.endDate
						? formatDateToQueryParam(filterParams.dateFilters.endDate)
						: undefined,
				status: !isEmpty(filterParams.statusFilters)
					? JSON.stringify(filterParams.statusFilters)
					: undefined,
				customer: !isEmpty(customerParam)
					? JSON.stringify(customerParam)
					: undefined,
				actionsOrder:
					filterParams.actionsOrder !== "default"
						? filterParams.actionsOrder
						: undefined,
				customerOrder:
					filterParams.customerOrder !== "default"
						? filterParams.customerOrder
						: undefined,
				durationOrder:
					filterParams.durationOrder !== "default"
						? filterParams.durationOrder
						: undefined,
				rateOrder:
					filterParams.rateOrder !== "default"
						? filterParams.rateOrder
						: undefined,
				statusOrder:
					filterParams.statusOrder !== "default"
						? filterParams.statusOrder
						: undefined,
				start_endOrder:
					filterParams.start_endOrder !== "default"
						? filterParams.start_endOrder
						: undefined,
				dateOrder:
					filterParams.dateOrder !== "default"
						? filterParams.dateOrder
						: undefined,
			},
		})
		.then(({ data }) => {
			const filename = "Interações.xlsx";
			const blob = new Blob([data], {
				type: "application/octet-stream",
			});
			const blobURL = window.URL.createObjectURL(blob);
			const tempLink = document.createElement("a");
			tempLink.style.display = "none";
			tempLink.href = blobURL;
			tempLink.download = filename;
			tempLink.click();
			window.URL.revokeObjectURL(blobURL);
		});
};

export const getAccountRegisters = async () =>
	await axiosInstance.get<AccountRegisterDetailsType>("/workspace/account/get");

export const storeAccountRegisters = async (
	state: AccountRegisterDetailsType,
	comparisonState: AccountRegisterDetailsType,
) => {
	const data = new FormData();
	if (state.name !== comparisonState.name) data.append("name", state.name);
	if (state.phone && state.phone !== comparisonState.phone) {
		data.append("phone", state.phone);
	}
	if (state.cpf && state.cpf !== comparisonState.cpf) {
		data.append("cpf", state.cpf);
	}
	if (state.pis && state.pis !== comparisonState.pis) {
		data.append("pis", state.pis);
	}
	if (state.password && state.password !== comparisonState.password) {
		data.append("password", state.password);
	}
	if (
		state.new_password &&
		state.new_password !== comparisonState.new_password
	) {
		data.append("new_password", state.new_password);
	}
	if (
		state.new_password_confirmation &&
		state.new_password_confirmation !==
			comparisonState.new_password_confirmation
	) {
		data.append("new_password_confirmation", state.new_password_confirmation);
	}
	if (state.citizenship && state.citizenship !== comparisonState.citizenship) {
		data.append("citizenship", state.citizenship.toString());
	}
	if (state.passport && state.passport !== comparisonState.passport) {
		data.append("passport", state.passport);
	}
	if (state.ssn && state.ssn !== comparisonState.ssn) {
		data.append("ssn", state.ssn);
	}
	if (
		state.provided_international_document_path &&
		state.provided_international_document_path !==
			comparisonState.provided_international_document_path
	) {
		data.append("identity", state.provided_international_document_path);
	}
	if (state.ethnicity && state.ethnicity !== comparisonState.ethnicity) {
		data.append("ethnicity", state.ethnicity);
	}

	return await axiosInstance.post("/workspace/account/register", data);
};

export const updateLinkedinCurriculum = async (state: {
	linkedin: string | undefined | null;
	curriculum: Blob | undefined | null;
	about: string | undefined | null;
}) => {
	const data = new FormData();

	if (state.linkedin) data.append("linkedinUrl", state.linkedin);
	if (state.curriculum != null) data.append("curriculum", state.curriculum);
	if (state.about != null) data.append("commentary", state.about);

	return await axiosInstance.post("/workspace/account/register", data);
};

export const sendEmailCode = async (state: AccountRegisterDetailsType) => {
	const data = new FormData();

	if (state.email) data.append("email", state.email);
	if (state.new_email) data.append("new_email", state.new_email);
	if (state.new_email_confirmation) {
		data.append("new_email_confirmation", state.new_email_confirmation);
	}

	return await axiosInstance.post(
		"/workspace/account/sendNewEmailValidation",
		data,
	);
};

export const validateCode = async (state: AccountRegisterDetailsType) => {
	const data = new FormData();

	if (state.email) data.append("email", state.email);
	if (state.new_email) data.append("new_email", state.new_email);
	if (state.new_email_confirmation) {
		data.append("new_email_confirmation", state.new_email_confirmation);
	}
	if (state.code) data.append("code", state.code);

	return await axiosInstance.post("/workspace/account/redefineEmail", data);
};

export const getBillingDetails = async () =>
	await axiosInstance.get<{
		current: BillingPayloadType;
		pending: BillingPayloadType;
	}>("/workspace/account/payment");

export const deleteBillingChangeRequest = async () =>
	await axiosInstance.delete("/workspace/account/payment");

export const postBillingDetails = async ({
	methodSelected,
	payload,
	instituteSelected,
}: {
	methodSelected: ReceiptMethodsType;
	payload: BillingEditPayloadType;
	instituteSelected: InstitutesType | undefined;
}) => {
	const formData = new FormData();

	if (methodSelected === "foreign_physical") {
		formData.append("registerType", "foreign_physical");
		payload.banks_country_name &&
			formData.append("banks_country_name", payload.banks_country_name);
		payload.aba_number && formData.append("aba_number", payload.aba_number);
		payload.address && formData.append("address", payload.address);
		payload.intermediary_swift_bic_code &&
			formData.append(
				"intermediary_swift_bic_code",
				payload.intermediary_swift_bic_code,
			);
		payload.swift_bic_code &&
			formData.append("swift_bic_code", payload.swift_bic_code);
		payload.internal_bank_name &&
			formData.append("internal_bank_name", payload.internal_bank_name);
		payload.contacorrente &&
			formData.append("contacorrente", payload.contacorrente);
		typeof payload.is_intermediary === "number" &&
			formData.append(
				"is_intermediary",
				payload.is_intermediary === 1 ? "1" : "0",
			);
	}

	if (methodSelected === "foreign") {
		formData.append("registerType", "foreign");
		payload.paypalEmail && formData.append("paypalEmail", payload.paypalEmail);
	}
	if (methodSelected === "physical") {
		formData.append("registerType", "physical");
		payload.fisico_nome_banco &&
			formData.append("nome_banco", payload.fisico_nome_banco);
		payload.fisico_numero_banco &&
			formData.append("numero_banco", payload.fisico_numero_banco);
		payload.fisico_agencia &&
			formData.append("agencia", payload.fisico_agencia);
		payload.fisico_contacorrente &&
			formData.append("contacorrente", payload.fisico_contacorrente);
		payload.fisico_digitoverificador &&
			formData.append(
				"digitoverificador",
				payload.fisico_digitoverificador.toString(),
			);
		payload.fisico_pix && formData.append("pix", payload.fisico_pix);
		formData.append("preferePix", payload.fisico_preferePix.toString());
	}
	if (methodSelected === "donation" && instituteSelected) {
		formData.append("donationCompany", instituteSelected);
		formData.append("registerType", "donation");
	}
	if (methodSelected === "business") {
		formData.append("registerType", "business");
		payload.socialName && formData.append("socialName", payload.socialName);
		payload.cnpj && formData.append("cnpj", payload.cnpj);
		formData.append("simplesNacional", payload.simplesNacional.toString());
		formData.append("accounting", payload.accounting);
		payload.accounting_email &&
			formData.append("accounting_email", payload.accounting_email.toString());
		payload.nome_banco && formData.append("nome_banco", payload.nome_banco);
		payload.numero_banco &&
			formData.append("numero_banco", payload.numero_banco);
		payload.agencia && formData.append("agencia", payload.agencia.toString());
		payload.contacorrente &&
			formData.append("contacorrente", payload.contacorrente);
		payload.digitoverificador &&
			formData.append(
				"digitoverificador",
				payload.digitoverificador.toString(),
			);
		payload.pix && formData.append("pix", payload.pix);
		formData.append("preferePix", payload.preferePix.toString());
	}
	return await axiosInstance.post("/workspace/account/payment", formData);
};

export const getBanks = async () =>
	await axiosInstance.get<BanksListOptionsType[]>("/assetsResources/banks");

export const getOccupations = async () =>
	await axiosInstance.get<Array<{ value: number; label: string }>>(
		"/assetsResources/occupations",
	);

export const getCompanies = async (companyName?: string | undefined) =>
	await axiosInstance.get<Array<{ value: number; label: string }>>(
		`/assetsResources/companies${
			companyName !== undefined ? `/${companyName}` : ""
		}`,
	);

export const createCompany = async (
	companyName: string,
): Promise<
	AxiosResponse<
		{
			id: number;
			name: string;
		},
		any
	>
> =>
	await axiosInstance.post<{ id: number; name: string }>(
		`/assetsResources/companies/${companyName}`,
	);

export const createOccupation = async (
	occupationName: string,
): Promise<
	AxiosResponse<
		{
			id: number;
			name: string;
		},
		any
	>
> =>
	await axiosInstance.post<{ id: number; name: string }>(
		`/assetsResources/occupations/${occupationName}`,
	);

export const createOccupationArea = async (
	occupationAreaName: string,
): Promise<
	AxiosResponse<
		{
			id: number;
			name: string;
		},
		any
	>
> =>
	await axiosInstance.post<{ id: number; name: string }>(
		`/assetsResources/occupation_areas/${occupationAreaName}`,
	);

export const getOccupationAreas = async () =>
	await axiosInstance.get<Array<{ value: number; label: string }>>(
		"/assetsResources/occupation_areas",
	);

export const postNewExperience = async (payload: NewExperienceType) => {
	const formData = new FormData();

	if (payload.company_id) {
		formData.append("company_id", payload.company_id.toString());
	}
	if (payload.entry_date) {
		formData.append("entry_date", `${payload.entry_date}-01`);
	}
	if (payload.exit_date) {
		formData.append("exit_date", `${payload.exit_date}-01`);
	}
	if (payload.responsabilities) {
		formData.append("responsabilities", payload.responsabilities);
	}
	if (payload.occupation_id) {
		formData.append("occupation_id", payload.occupation_id.toString());
	}
	if (payload.occupation_area_id) {
		formData.append(
			"occupation_area_id",
			payload.occupation_area_id.toString(),
		);
	}

	return await axiosInstance.post(
		"/workspace/account/companyExperience",
		formData,
	);
};

export const putEditExperience = async (payload: EditExperiencesType) => {
	const formData = new FormData();

	if (payload.id) formData.append("id", payload.id.toString());
	if (payload.companyId) {
		formData.append("company_id", payload.companyId.toString());
	}
	if (payload.initialDate) {
		formData.append("entry_date", `${payload.initialDate}-01`);
	}
	if (payload.endDate) formData.append("exit_date", `${payload.endDate}-01`);
	if (payload.occupation_id) {
		formData.append("occupation_id", payload.occupation_id.toString());
	}
	if (payload.occupation_area_id) {
		formData.append(
			"occupation_area_id",
			payload.occupation_area_id.toString(),
		);
	}
	if (payload.responsabilities) {
		formData.append("responsabilities", payload.responsabilities);
	} else {
		formData.append("responsabilities", "");
	}
	formData.append("_method", "PUT");

	return await axiosInstance.post(
		"/workspace/account/companyExperience",
		formData,
	);
};

export const deleteExperience = async (
	experience_id: number,
): Promise<AxiosResponse<any, any>> =>
	await axiosInstance.delete(
		`/workspace/account/companyExperience/${experience_id}`,
	);

export const finishInteraction = async (
	payload: Omit<finishInteractionType, "loading">,
): Promise<AxiosResponse<any, any>> => {
	const data = {
		interaction_id: payload.interaction_id,
		comment: payload.comment,
		rating: payload.rating,
		events: [
			{
				event_time: `${payload.event_time ?? ""} ${
					payload.event.view_in ?? ""
				}:00`,
				event: "VIEW_IN",
			},
			{
				event_time: `${payload.event_time ?? ""} ${
					payload.event.view_out ?? ""
				}:00`,
				event: "VIEW_OUT",
			},
		],
	};

	return await axiosInstance.post(
		"/workspace/interaction/registerFinish",
		data,
	);
};

export const interactionDidNotOccurred = async ({
	interaction_id,
	reason,
}: {
	interaction_id: number;
	reason: string;
}) => {
	const formData = new FormData();

	formData.append("interaction_id", interaction_id.toString());
	formData.append("reason", reason);

	return await axiosInstance.post(
		"/workspace/interaction/markUnfulfilled",
		formData,
	);
};

export const dispatchRateInteraction = async ({
	interaction_id,
	rating,
	comment,
}: {
	interaction_id: number | undefined;
	rating: 1 | 2 | 3 | 4 | 5 | undefined;
	comment: string | undefined;
}): Promise<AxiosResponse<any, any>> => {
	const formData = new FormData();

	if (interaction_id) {
		formData.append("interaction_id", interaction_id.toString());
	}
	if (rating) formData.append("rating", rating.toString());
	if (comment && comment?.length > 1) formData.append("comment", comment);

	return await axiosInstance.post(
		"/workspace/interaction/registerRating",
		formData,
	);
};

export const generateReferralToken = async () =>
	await axiosInstance.post("/workspace/recommendation/issueGuestToken");

export const getInteractionAvailabilities = async (interaction_id: number) =>
	await axiosInstance.get<{
		availables: Array<{ id: number; event_date: string }>;
		selecteds: Array<{
			id: number;
			event_date: string;
			event_hour: string;
		}>;
	}>(`/workspace/interaction/interactionAvailabilities/${interaction_id}`);

export const postAvailabilities = async (payload: {
	interaction_id: number | undefined;
	selected_availabilities: Array<{ date: string; hour: string }>;
}) =>
	await axiosInstance.post("/workspace/interaction/interactionAvailabilities", {
		interaction_id: payload.interaction_id,
		selected_availabilities: payload.selected_availabilities,
	});

export const validateGuestToken = async (token: string | null | undefined) =>
	await axiosInstance.get(
		`/SpontaneousExpertRegister/validateGuestToken/${token}`,
	);

export const getScheduledInteractions = async () =>
	await axiosInstance.get<DateEventType>("/workspace/interaction/scheduleds");

export const getNotifications = async () =>
	await axiosInstance.get<notificationsType>(
		"/workspace/account/notifications",
	);

export const registerUserActions = async (action: string) =>
	await axiosInstance.post("/workspace/account/screenView", {
		screen: action,
	});

export const getCompliancePendingData = async (): Promise<
	AxiosResponse<any, any>
> => await axiosInstance.get("/workspace/account/complianceDisapproved");

export const postComplianceData = async (payload: {
	birthDate?: string;
	cpf?: string;
	passport?: string;
	telephone?: string;
}): Promise<AxiosResponse<any, any>> => {
	const formData = new FormData();

	if (payload.birthDate !== undefined)
		formData.append(
			"birthDate",
			`${new Date(payload.birthDate).toLocaleDateString("pt-BR", {
				year: "numeric",
			})}-${new Date(payload.birthDate).toLocaleDateString("pt-BR", {
				month: "2-digit",
			})}-${new Date(payload.birthDate).toLocaleDateString("pt-BR", {
				day: "2-digit",
			})}`,
		);
	if (payload.cpf !== undefined) formData.append("cpf", payload.cpf);
	if (payload.passport !== undefined)
		formData.append("passport", payload.passport);
	if (payload.telephone !== undefined)
		formData.append("telephone", payload.telephone);

	return await axiosInstance.post(
		"/workspace/account/updateComplianceData",
		formData,
	);
};

export const getCountries = async () =>
	await axiosInstance.get<Array<{ id: number; country: string }>>(
		"/publicAssets/countries",
	);
